import { useQuery } from 'react-query';
import axios from "axios";
import { API_URL, CACHE_TIME } from '../../index';
import BlogPreview from './BlogPreview';
import './Blog.css';

const blogURL = "blog/";

const BlogList = () => {
    const blog = useQuery('blog', () => axios.get(API_URL + blogURL).then(response => response.data), { cacheTime: CACHE_TIME });

    return (
        <div className="preview-list" style={{width: "100%"}}>
            {!blog || !blog.data || blog.data.length <= 0 ? (<></>) :
                blog.data.toReversed().map(blogItem => (<BlogPreview blog={blogItem} key={"blog" + blogItem.pk} />
                ))
            }
        </div>
    );
}

export default BlogList;