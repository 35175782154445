import { useQuery } from 'react-query';
import axios from "axios";
import { API_URL, CACHE_TIME } from '../../index';
import SaleOffer from './SaleOffer';

const saleOfferURL = "saleoffers/";

const SaleOfferList = () => {
    const saleOffers = useQuery('saleoffers', () => axios.get(API_URL + saleOfferURL).then(response => response.data), { cacheTime: CACHE_TIME });

    return (
        <div>
            {!saleOffers || !saleOffers.data || saleOffers.data.length <= 0 ? (
                <div>Nothing yet</div>
            ) :
                saleOffers.data.toReversed().map(offer => (<SaleOffer offer={offer} key={"saleoffer"+offer.pk} />))
            }
        </div>
    );
};

export default SaleOfferList;