import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import { useParams } from 'react-router';
import "./Blog.css";
import { API_URL, prepareImages } from '../../index';
import * as DOMPurify from 'dompurify';
import Footer from '../PageComponents/Footer';
import Header from '../PageComponents/Header';
import ErrorWrapper from "../PageComponents/ErrorWrapper";
import RouteUrl from '../RouteEnum';

const blogURL = "blog/";
const filterURL = "blog/filter/";

const BlogDetailed = () => {
    const { id } = useParams();
    const [blog, setBlog] = useState({});
    const [prevAndNextBlog, setPrevAndNextBlog] = useState({prev: null, next: null});
    const [headerData, setHeaderData] = useState({});
    const [error, setError] = useState(false);
    const path = [{ link: "../" + RouteUrl.blog, title: "Блог" }, { link: "", title: blog.title }];

    useEffect(() => {
        window.scrollTo(0, 0);
        getBlog();
    }, [id]);

    const getBlog = () => {
        axios.get(API_URL + blogURL + id).then(data => {
            setBlog(data.data);
            setHeaderData({
                image: data.data.image_header,
                SEO_title: data.data.SEO_title,
                SEO_keywords: data.data.SEO_keywords,
                SEO_desc: data.data.SEO_desc
            });
            axios.get(API_URL + filterURL + data.data.pk).then(neighborData => {
                setPrevAndNextBlog({ prev: neighborData.data[0], next: neighborData.data[1] });
            });
        }).catch(err => setError(true));
    };

    const addMainHeader = (text) => {
        return text && text.replace(/<h1>/g, '<h1 class="main-header">')
    }

    const reload = () => window.location.reload();

    return (
        <ErrorWrapper error={error}>
            <Header path={path} data={headerData} />
            <div className="frame">
                {blog.title && <h1 className="main-header">{blog.title.toUpperCase().split('|').join('\n')}</h1>}
                <div className="procedure-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(addMainHeader(prepareImages(blog.text))) }}></div>
                <div className="blog-navigation-container">
                    {prevAndNextBlog.prev && prevAndNextBlog.prev.title && (
                        <Link onClick={getBlog} to={"../../" + RouteUrl.blog + (prevAndNextBlog.prev.url_address ? prevAndNextBlog.prev.url_address : prevAndNextBlog.prev.pk)} relative="path">
                            <div>&larr; Попередній запис: {prevAndNextBlog.prev.title}</div>
                        </Link>)}
                    <div style={{width: "100%", marginBottom: "150px"}}></div>
                    {prevAndNextBlog.next && prevAndNextBlog.next.title && (
                        <Link onClick={getBlog} to={"../../" + RouteUrl.blog + (prevAndNextBlog.next.url_address ? prevAndNextBlog.next.url_address : prevAndNextBlog.next.pk)} relative="path">
                            <div>Наступний запис: {prevAndNextBlog.next.title} &rarr;</div>
                        </Link>)}
                </div>
            </div>
            
            <Footer nested="2" />
        </ErrorWrapper>
    );
}

export default BlogDetailed;