import axios from "axios";
import { API_URL, CACHE_TIME } from '../../index';
import PackageOffer from './PackageOffer';
import { useQuery } from 'react-query';

const packageOfferURL = "packageoffers/";

const PackageOfferList = ({ show }) => {
    const packageOffers = useQuery('packageoffers', () => axios.get(API_URL + packageOfferURL).then(response => response.data), { cacheTime: CACHE_TIME });

    return (
        <div style={{
            justifyContent: "center"
        }}>
            {!packageOffers || !packageOffers.data || packageOffers.data.length <= 0 ? (
                <div>Nothing yet</div>
            ) :
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap"
                }} >{packageOffers.data.toReversed().slice(0, show > 0 ? show : packageOffers.data.length).map(offer => (<PackageOffer offer={offer} key={"packageoffer" + offer.pk} />))}</div>
            }
        </div>
        );
};

export default PackageOfferList;